import {createApp} from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import router from '@/router';
import 'element-plus/dist/index.css'
import store from '@/store';


createApp(App)
  .use(router)
  .use(store)
  .use(ElementPlus, {size: 'small', zIndex: 1000})
  .mount('#app')
