const routerConfig = [
  {
    path: '/',//路由
    name: 'Home',//路由名字
    component: () => import('@/components/view/home'),//组件
    navPath: '首页',//导航名称
    showNav: true,//是否在导航栏显示
    pageTitle: '首页',//网页标签页标题
  },
  {
    path: '/login',//路由
    name: 'login',//路由名字
    component: () => import('@/components/view/user/log-in'),//组件
    navPath: '登录',//导航名称
    showNav: false,//是否在导航栏显示
    pageTitle: '登录',//网页标签页标题
  },
  {
    path: '/userInfo',//路由
    name: 'userInfo',//路由名字
    component: () => import('@/components/view/user/user-info'),//组件
    navPath: '个人信息',//导航名称
    showNav: false,//是否在导航栏显示
    pageTitle: '个人信息',//网页标签页标题
  },
  {
    path: '/vps',//路由
    name: 'vps',//路由名字
    component: () => import('@/components/view/vps'),//组件
    navPath: '服务器VPS',//导航名称
    showNav: false,//是否在导航栏显示
    pageTitle: '服务器VPS',//网页标签页标题
  },
  {
    path: '/auth',//路由
    name: 'auth',//路由名字
    component: () => import('@/components/view/auth/auth'),//组件
    navPath: 'auth',//导航名称
    showNav: false,//是否在导航栏显示
    pageTitle: 'auth',//网页标签页标题
  }
]
export default routerConfig;