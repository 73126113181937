import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      // 登录状态
      loginStatus: false,
      member: {
        wangning: 101,
        ranlin: 100
      }
    }
  },
  mutations: {
    setLoginStatus: (state, value) => {
      state.loginStatus = value;
    }
  }
});

export default store;