import { createRouter, createWebHistory } from "vue-router";
import routes from '@/router/routerConfig';

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !window.localStorage.getItem('sentimentName')) {
    next('/login');
  } else {
    next();
  }
});
export default router;